import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  unstable_composeUploadHandlers,
  unstable_createMemoryUploadHandler,
  unstable_parseMultipartFormData,
  UploadHandler
} from '@remix-run/cloudflare';
import { validate, version } from 'uuid';

export const getRequestBody = async <T>(request: Request): Promise<T> => {
  const body = Object.fromEntries(await request.formData());
  delete body.csrf;
  return body as T;
};

export const handleMultipartFormData = async (
  args: ActionFunctionArgs,
  handler: UploadHandler
): Promise<FormData> => {
  const uploadHandler = unstable_composeUploadHandlers(async (part) => {
    if (!part?.filename) {
      return undefined;
    }

    return handler(part);
  }, unstable_createMemoryUploadHandler());

  return unstable_parseMultipartFormData(args.request, uploadHandler);
};

export const isValidMimeType = (type: string) => {
  return ['image/jpeg', 'image/png'].includes(type);
};

export const toArrayBuffer = async (asyncIterator: AsyncIterable<Uint8Array>) => {
  const chunks: Uint8Array[] = [];
  for await (const i of asyncIterator) chunks.push(i);

  const flattened = new Uint8Array(chunks.reduce((total, chunk) => total + chunk.length, 0));
  let offset = 0;
  for (const chunk of chunks) {
    flattened.set(chunk, offset);
    offset += chunk.length;
  }

  return flattened.buffer;
};

export const toMegaBytes = (bytes: number) => {
  return bytes / 1000000;
};

export const getTeamId = (args: LoaderFunctionArgs | ActionFunctionArgs): string | null => {
  const teamId = args.params.team;
  if (!teamId) {
    return null;
  }

  if (!validate(teamId)) {
    return null;
  }

  if (version(teamId) !== 4) {
    return null;
  }

  return teamId;
};

export const getInitials = (name: string) => {
  const words = name.split(' ');
  return `${words?.[0]?.[0] || ''}${words?.[1]?.[0] || ''}`;
};

export const getUserInitials = (firstName: string, lastName: string) => {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toLocaleUpperCase();
};
